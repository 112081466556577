import React, { useCallback, useEffect, useState } from "react";
import styles from "../CreateCollection/CreateCollection.module.scss";
import { useDropzone } from "react-dropzone";
import CloseIcon from "@mui/icons-material/Close";
import JSZip from "jszip";
import Button from "app/components/button/button";
import { addFileBenefit, editFileBenefit } from "app/helpers/FileBenefit";

export default function AddFileStructure({
  setFileName,
  fileName,
  setFile,
  file,
  fansCollectionId,
  cookie,
  fileId,
  setCollectionBenefits,
  setFetchTrigger,
  setAddFile,
  addFile,
  editFile,
  setEditFile,
}) {
  const [zipContents, setZipContents] = useState<string[]>([]);
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newFile, setNewFile] = useState(null);
  const [editingFile, setEditingFile] = useState(null);

  const TrackSizeValidator = (newFile: File) => {
    const maxFileSize = 25 * 1024 * 1024; // 25MB

    if (newFile.size > maxFileSize) {
      return {
        code: "name-too-large",
        message: `File size exceeds the maximum allowed size of 25MB.`,
      };
    }
    return null;
  };

  useEffect(() => {
    if (editFile) {
      const fileToEdit = file?.find((video) => video.id === fileId);
      setEditingFile(fileToEdit);
    }
  }, [file]);

  useEffect(() => {
    if (editFile) {
      setFileName(editingFile?.name);
    }
  }, [editingFile]);

  useEffect(() => {
    if (editFile) {
      setReady(true);
    } else {
      setReady(!!fileName && newFile?.length > 0);
    }
  }, [newFile, fileName]);

  useEffect(() => {
    // Clean up the URL.createObjectURL
    return () => {
      newFile?.forEach((newFile) => URL.revokeObjectURL(newFile?.preview));
    };
  }, [file]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newFile = acceptedFiles?.map((file) => ({
        file,
      }));
      setNewFile(newFile); // Only one file is allowed, so we overwrite the state

      const zipFile = acceptedFiles[0];
      if (zipFile?.type === "application/zip") {
        const zip = new JSZip();
        zip.loadAsync(zipFile).then((zipContent) => {
          const fileNames = [];
          zipContent?.forEach((relativePath, zipEntry) => {
            fileNames?.push(zipEntry.name);
          });
          setZipContents(fileNames);
        });
      } else {
        setZipContents([]);
      }
    },
    [setNewFile]
  );

  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      onDrop,
      accept: { "application/zip": [".zip"] },
      maxFiles: 1,
      validator: TrackSizeValidator,
      disabled: editFile, // Disable dropzone if editing
    });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.name}>
      {file?.name} - {file?.size} bytes
      <ul>
        {errors?.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  /* ====================== */
  /* upload file of the Collection */
  /* ======== Start ======= */
  const createCollectionVideo = async () => {
    try {
      setLoading(true);

      const response = await addFileBenefit(
        fansCollectionId,
        cookie,
        fileName,
        newFile
      );

      if (response) {
        setCollectionBenefits([]);
        setAddFile(false);
        setFetchTrigger((prev) => !prev);
      } else {
        // Handle error
        alert("Failed to upload video");
      }
    } catch (error) {
      console.error("Error creating album:", error);
      alert("An error occurred while creating the album");
    } finally {
      setLoading(false);
    }
  };
  /* ====================== */
  /* upload file of the Collection  */
  /* ======== End ======= */

  /* ====================== */
  /* edit file of the Collection */
  /* ======== Start ======= */

  const handleEdit = async () => {
    try {
      setLoading(true);
      const response = await editFileBenefit(
        fansCollectionId,
        cookie,
        fileName,
        fileId
      );
      if (response) {
        setFetchTrigger((prev) => !prev);
        setEditFile(false);
        setFileName("");
      } else {
        alert("Failed to upload video");
      }
    } catch (error) {
      console.error("Error creating album:", error);
      alert("An error occurred while creating the album");
    } finally {
      setLoading(false);
    }
  };

  /* ====================== */
  /* edit file of the Collection  */
  /* ======== End ======= */

  return (
    <div className={styles.album_infos}>
      <div className={styles.inputs_album}>
        <div className={styles.input_label}>
          <span>File Name</span>
          <div className={styles.input_song}>
            <input
              type="text"
              value={fileName}
              onChange={(e) => setFileName(e?.target?.value)}
            />
          </div>
        </div>
      </div>

      {newFile?.length > 0 && !editFile ? (
        <div className={styles.video_preview}>
          <div className={styles.close_icon} onClick={() => setNewFile([])}>
            <CloseIcon />
          </div>
          {zipContents.length > 0 && (
            <div className={styles.zip_contents}>
              <h3>Zip File Contents:</h3>
              <ul className={styles.file_list}>
                {zipContents.map((fileName, index) => (
                  <li key={index}>{fileName}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      ) : !editFile ? (
        <div className={styles.drag_drop_contianer}>
          <div {...getRootProps()} className={styles.drag_drop}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <div>
                <p>Drag 'n' drop your file, or click to select file</p>
                <em>(1 file Max - 25MB max size)</em>
              </div>
            )}
          </div>
          {fileRejectionItems.length > 0 && (
            <div className={styles.file_rejections}>
              <ul>{fileRejectionItems}</ul>
            </div>
          )}
        </div>
      ) : null}
      <div className="defaultModalButtons">
        <Button
          size="sm"
          onClick={addFile ? createCollectionVideo : handleEdit}
          disabled={loading || !ready}
          loading={loading}
        >
          Done
        </Button>
      </div>
    </div>
  );
}
