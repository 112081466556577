import Modal from "app/components/Modal";
import React, { useState } from "react";
import AddAlbumStructure from "./AddAlbumStructure";
import AddVideoStructure from "./AddVideoStructure";
import AddFileStructure from "./AddFileStructure";

export default function AddBenefitsModal({
  addSong,
  setAddSong,
  genres,
  songFile,
  genre,
  tracks,
  setTracks,
  handleStatusGenre,
  albumName,
  setAlbumName,
  edit,
  editAlbum,
  setEditAlbum,
  addVideo,
  setAddVideo,
  video,
  setVideo,
  videoName,
  setVideoName,
  createCollectionVideo,
  editVideo,
  setEditVideo,
  addFile,
  setAddFile,
  setFileName,
  fileName,
  setFile,
  file,
  fansCollectionId,
  videoCollectionId,
  setFetchTrigger,
  setCollectionBenefits,
  cookie,
  albumId,
  fileId,
  editFile,
  setEditFile,
  album,
  editAlbumId,
  setGenre,
}: {
  edit?: boolean;
  addSong: boolean;
  setAddSong: any;
  genres: any;
  songFile: any;
  refSong: any;
  genre: any;
  setGenre: any;
  tracks: any;
  setTracks: any;
  handleStatusGenre: any;
  albumName: any;
  setAlbumName: any;
  editAlbum?: boolean;
  setEditAlbum?: any;
  loading?: boolean;
  addVideo?: boolean;
  setAddVideo?: any;
  video?: string;
  setVideo?: any;
  videoName?: string;
  setVideoName?: any;
  fansCollectionId?: string;
  cookie?: string;
  createCollectionVideo?: any;
  editVideo?: boolean;
  setEditVideo?: any;
  addFile?: boolean;
  setAddFile?: any;
  setFileName?: any;
  fileName?: string;
  setFile?: any;
  file?: any;
  videoCollectionId?: string;
  setFetchTrigger?: any;
  setCollectionBenefits?: any;
  albumId?: string;
  fileId?: string;
  editFile?: boolean;
  setEditFile?: any;
  album?: any;
  editAlbumId?: string;
}) {
  return (
    <Modal
      isOpen={
        editAlbum
          ? editAlbum
          : editVideo
          ? editVideo
          : addVideo
          ? addVideo
          : addSong
          ? addSong
          : addFile
          ? addFile
          : editFile
          ? editFile
          : false
      }
      onClose={() => {
        if (editAlbum) {
          setEditAlbum(false);
          setGenre("");
          setTracks([]);
          setAlbumName(null);
        } else if (editVideo) {
          setEditVideo(false);
          setVideoName("");
        } else if (addVideo) {
          setAddVideo(false);
        } else if (addSong) {
          setAddSong(false);
          setGenre("");
          setTracks([]);
          setAlbumName(null);
        } else if (addFile) {
          setAddFile(false);
        } else if (editFile) {
          setEditFile(false);
          setFileName("");
        }
      }}
      title={
        editAlbum
          ? "Edit Album"
          : editVideo
          ? "Edit Video"
          : addVideo
          ? "Add Video"
          : addSong
          ? "Add Tracks"
          : addFile
          ? "Add File"
          : editFile
          ? "Edit Folder"
          : null
      }
      customSubClass="defaultModal"
    >
      <div className="defaultModalContent">
        {addSong || editAlbum ? (
          <AddAlbumStructure
            genres={genres}
            songFile={songFile}
            genre={genre}
            setGenre={setGenre}
            tracks={tracks}
            setTracks={setTracks}
            handleStatusGenre={handleStatusGenre}
            albumName={albumName}
            setAlbumName={setAlbumName}
            edit={edit}
            fansCollectionId={fansCollectionId}
            cookie={cookie}
            setCollectionBenefits={setCollectionBenefits}
            setAddSong={setAddSong}
            setFetchTrigger={setFetchTrigger}
            albumId={albumId}
            setEditAlbum={setEditAlbum}
            editAlbum={editAlbum}
            addSong={addSong}
            album={album}
            editAlbumId={editAlbumId}
          />
        ) : addVideo || editVideo ? (
          <AddVideoStructure
            video={video}
            setEditVideo={setEditVideo}
            setVideo={setVideo}
            videoName={videoName}
            setVideoName={setVideoName}
            editVideo={editVideo}
            createCollectionVideo={createCollectionVideo}
            addVideo={addVideo}
            fansCollectionId={fansCollectionId}
            setAddVideo={setAddVideo}
            videoCollectionId={videoCollectionId}
            setFetchTrigger={setFetchTrigger}
            setCollectionBenefits={setCollectionBenefits}
          />
        ) : addFile || editFile ? (
          <AddFileStructure
            setFileName={setFileName}
            fileName={fileName}
            setFile={setFile}
            file={file}
            cookie={cookie}
            setCollectionBenefits={setCollectionBenefits}
            setFetchTrigger={setFetchTrigger}
            setAddFile={setAddFile}
            fansCollectionId={fansCollectionId}
            fileId={fileId}
            addFile={addFile}
            editFile={editFile}
            setEditFile={setEditFile}
          />
        ) : null}
      </div>
    </Modal>
  );
}
