import styles from "../CreateCollection/CreateCollection.module.scss";
import Button from "app/components/button/button";
import EditIcon from "@mui/icons-material/Edit";
import { blue } from "@mui/material/colors";
import ClearIcon from "@mui/icons-material/Clear";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  createAlbum,
  editCollectionAlbumEdit,
  editTrackName,
} from "app/helpers/CreateNft";

// Define the props interface
interface AddAlbumStructureProps {
  genres: { id: string; name: string }[];
  songFile?: File;
  genre: string;
  tracks: any;
  setTracks: React.Dispatch<React.SetStateAction<any[]>>;
  handleStatusGenre: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  albumName: string;
  setAlbumName: React.Dispatch<React.SetStateAction<string>>;
  edit?: boolean;
  fansCollectionId: string;
  cookie: string;
  setCollectionBenefits: any;
  setAddSong: any;
  setFetchTrigger: any;
  albumId: string;
  setEditAlbum: any;
  editAlbum: boolean;
  addSong: boolean;
  album: any;
  editAlbumId: string;
  setGenre: any;
}

const AddAlbumStructure: React.FC<AddAlbumStructureProps> = ({
  genres,
  genre,
  tracks,
  setTracks,
  handleStatusGenre,
  albumName,
  setAlbumName,
  edit,
  fansCollectionId,
  cookie,
  setCollectionBenefits,
  setAddSong,
  setFetchTrigger,
  albumId,
  setEditAlbum,
  editAlbum,
  addSong,
  album,
  editAlbumId,
  setGenre,
}) => {
  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(false);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [editingName, setEditingName] = useState<string>("");
  const [editingAlbum, setEditingAlbum] = useState(null);
  const maxFileSize = 300 * 1024 * 1024; // 300MB

  const handleDeleteTrack = (index: number) => {
    setTracks((prevTracks) => prevTracks.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (editAlbum) {
      const albumToEdit = album?.find((album) => album.id === editAlbumId);
      setEditingAlbum(albumToEdit);
    }
  }, [album]);

  useEffect(() => {
    if (editAlbum && editingAlbum) {
      setAlbumName(editingAlbum.name);
      setGenre(editingAlbum.genre.id);
    }
  }, [editAlbum, editingAlbum]);

  useEffect(() => {
    if (!editAlbum) {
      setReady(!!albumName && !!genre && tracks.length > 0);
    } else {
      setReady(true);
    }
  }, [albumName, genre, tracks, setReady]);

  const TrackSizeValidator = (file: File) => {
    if (file.size > maxFileSize) {
      return {
        code: "name-too-large",
        message: `Song size exceeds the maximum allowed size of 25MB.`,
      };
    }
    return null;
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newTracks = acceptedFiles.map((file) => ({ file }));
      setTracks((prevTracks) => [...prevTracks, ...newTracks]);
    },
    [setTracks]
  );

  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      onDrop,
      accept: { "audio/*": [".mp3", ".mpeg", ".wav"] },
      maxFiles: 20,
      validator: TrackSizeValidator,
    });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  // when click for edit a song name, set the index of the song, and set the name that will be edited
  const handleEditClick = (index: number, currentTrack: any) => {
    if (currentTrack?.file instanceof File) {
      setEditingIndex(index);
      setEditingName(currentTrack?.file?.name);
    } else if (typeof currentTrack === "object" && "track" in currentTrack) {
      setEditingIndex(index);
      setEditingName(currentTrack?.track?.title);
    }
  };

  // when typing to change the new song or track name
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingName(e.target.value);
  };

  // when the user edit the name of the file
  const handleSaveName = (index: number) => {
    setTracks((prevTracks) =>
      prevTracks?.map((track, i) => {
        if (i === index) {
          const updatedFile = new File([track?.file], editingName, {
            type: track?.file?.type,
          });
          return { ...track, file: updatedFile };
        }
        return track;
      })
    );
    setEditingIndex(null);
    setEditingName("");
  };

  /* ====================== */
  /* edit trackname of album */
  /* ======== Start ======= */

  const handleEditTrackName = async (track) => {
    const trackId = track?.track?.id;
    try {
      const response = await editTrackName(cookie, trackId, editingName);
      setEditingIndex(null);
      setEditingName("");
      setFetchTrigger((prev) => !prev);
    } catch (error) {
      console.error(error);
    }
  };

  /* ====================== */
  /* edit trackname of album */
  /* ======== End ======= */

  /* ====================== */
  /* create the track album of the Collection */
  /* ======== Start ======= */
  const createCollectionAlbum = async () => {
    try {
      setLoading(true);

      const query = `name: "${albumName}", genre: { id: ${genre} }`;

      const response = await createAlbum(
        fansCollectionId,
        cookie,
        query,
        tracks
      );

      if (response) {
        // Handle success, e.g., reset the form, close the modal, etc.
        setCollectionBenefits([]);
        setFetchTrigger((prev) => !prev);
        setAlbumName("");
        setGenre("");
        setEditingAlbum("");
        setTracks([]);
        setAddSong(false);
      } else {
        // Handle error
        alert("Failed to create album");
      }
    } catch (error) {
      console.error("Error creating album:", error);
      alert("An error occurred while creating the album");
    } finally {
      setLoading(false);
    }
  };
  /* ====================== */
  /* create the track album of the Collection  */
  /* ======== End ======= */

  /* ====================== */
  /* edit name & genre of the Collection album */
  /* ======== Start ======= */
  const editCollectionAlbum = async () => {
    try {
      setLoading(true);
      const query = `name: "${albumName}", genre: { id: ${genre} }`;
      const response = await editCollectionAlbumEdit(
        fansCollectionId,
        albumId,
        cookie,
        query,
        tracks
      );

      if (response) {
        // Handle success, e.g., reset the form, close the modal, etc.
        setCollectionBenefits([]);
        setEditAlbum(false);
        setFetchTrigger((prev) => !prev);
        setAlbumName("");
        setGenre("");
        setEditingAlbum("");
        setTracks([]);
      } else {
        // Handle error
        alert("Failed to create album");
      }
    } catch (error) {
      console.error("Error creating album:", error);
      alert("An error occurred while creating the album");
    } finally {
      setLoading(false);
    }
  };
  /* ====================== */
  /* edit name & genre of the Collection album */
  /* ======== End ======= */

  return (
    <div className={styles.album_infos}>
      <div className={styles.inputs_album}>
        <div className={styles.input_label}>
          <span>Album Name</span>
          <div className={styles.input_song}>
            <input
              type="text"
              value={albumName}
              onChange={(e) => setAlbumName(e.target.value)}
            />
          </div>
        </div>
        <div className={styles.input_label}>
          <span>Genre</span>
          <div className={styles.input_song}>
            <select
              name="genre"
              id="genre"
              required
              onChange={handleStatusGenre}
              value={genre}
            >
              {genres?.map((gen) => (
                <option key={gen.id} value={gen.id} label={gen.name}>
                  {gen.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className={styles.drag_drop_contianer}>
        <div {...getRootProps()} className={styles.drag_drop}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <div>
              <p>Drag 'n' drop your tracks, or click to select files</p>
              <em>(20 files Max - 25MB each)</em>
            </div>
          )}
        </div>
        {editAlbum
          ? editingAlbum?.tracks?.length > 0 && (
              <div className={styles.track_list_container}>
                <h4>Tracks:</h4>
                <div className={styles.track_list}>
                  {editingAlbum?.tracks?.map((track, index) => (
                    <div key={index} className={styles.track_item}>
                      {editingIndex === index ? (
                        <div className={styles.edit_input}>
                          <input
                            type="text"
                            value={editingName}
                            onChange={handleNameChange}
                          />
                          <div className={styles.edit_buttons}>
                            <Button
                              size="sm"
                              onClick={() => {
                                typeof track === "object" && "track" in track
                                  ? handleEditTrackName(track)
                                  : handleSaveName(index);
                              }}
                            >
                              Save
                            </Button>
                            <Button
                              size="sm"
                              onClick={() => setEditingIndex(null)}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <>
                          <span>{index + 1}.</span>
                          <span>
                            {track?.track?.title ?? track?.file?.name}
                          </span>
                          <div className={styles.icon}>
                            <EditIcon
                              fontSize="small"
                              sx={{ color: blue[700] }}
                              onClick={() => handleEditClick(index, track)}
                            />
                          </div>
                          {!edit && (
                            <div className={styles.icon}>
                              <ClearIcon
                                onClick={() => handleDeleteTrack(index)}
                                sx={{ color: blue[700] }}
                              />
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  ))}
                </div>
                {fileRejections?.length > 0 && (
                  <div className={styles.track_list}>
                    <h4>Rejected files</h4>
                    <ul>{fileRejectionItems}</ul>
                  </div>
                )}
              </div>
            )
          : tracks?.length > 0 && (
              <div className={styles.track_list_container}>
                <h4>Tracks:</h4>
                <div className={styles.track_list}>
                  {tracks?.map((track, index) => (
                    <div key={index} className={styles.track_item}>
                      {editingIndex === index ? (
                        <div className={styles.edit_input}>
                          <input
                            type="text"
                            value={editingName}
                            onChange={handleNameChange}
                          />
                          <div className={styles.edit_buttons}>
                            <Button
                              size="sm"
                              onClick={() => {
                                typeof track === "object" && "track" in track
                                  ? handleEditTrackName(track)
                                  : handleSaveName(index);
                              }}
                            >
                              Save
                            </Button>
                            <Button
                              size="sm"
                              onClick={() => setEditingIndex(null)}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <>
                          <span>{index + 1}.</span>
                          <span>
                            {track?.track?.title ?? track?.file?.name}
                          </span>
                          <div className={styles.icon}>
                            <EditIcon
                              fontSize="small"
                              sx={{ color: blue[700] }}
                              onClick={() => handleEditClick(index, track)}
                            />
                          </div>
                          {!edit && (
                            <div className={styles.icon}>
                              <ClearIcon
                                onClick={() => handleDeleteTrack(index)}
                                sx={{ color: blue[700] }}
                              />
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  ))}
                </div>
                {fileRejections?.length > 0 && (
                  <div className={styles.track_list}>
                    <h4>Rejected files</h4>
                    <ul>{fileRejectionItems}</ul>
                  </div>
                )}
              </div>
            )}
      </div>
      <div className="defaultModalButtons">
        <Button
          size="sm"
          onClick={
            editAlbum
              ? editCollectionAlbum
              : addSong
              ? createCollectionAlbum
              : null
          }
          disabled={loading ? loading : !ready}
          loading={loading}
        >
          Done
        </Button>
      </div>
    </div>
  );
};

export default AddAlbumStructure;
